export enum Icons {
  Amend = 'material-symbols:amend',
  MessageQuestion = 'mdi:comment-question-outline',
  Html = 'mdi:language-html5',
  Archive = 'ion:archive-outline',
  Flag = 'mdi:flag',
  Calender = 'uil:calender',
  ArrowBack = 'eva:arrow-back-outline',
  ArrowBackIOS = 'ic:baseline-arrow-back-ios',
  ArrowForwardIOS = 'ic:baseline-arrow-forward-ios',
  ArrowLeft = 'akar-icons:arrow-left',
  ArrowLeft90 = 'bi:arrow-90deg-left',
  ArrowRight = 'akar-icons:arrow-right',
  ArrowRightLight = 'codicon:arrow-right',
  FullArrowUp = 'akar-icons:arrow-up',
  FullArrowDown = 'akar-icons:arrow-down',
  ArrowDown = 'ep:arrow-down',
  ArrowDown90 = 'bi:arrow-90deg-down',
  ArrowDownRight = 'akar-icons:arrow-down-right',
  ArrowLeftDash = 'dashicons:arrow-left-alt2',
  ArrowRightDash = 'dashicons:arrow-right-alt2',
  ArrowRightBold = 'solar:alt-arrow-right-bold',
  ArrowCounterClockwise = 'akar-icons:arrow-counter-clockwise',
  ClockCounterClockwise = 'ph:clock-counter-clockwise-fill',
  ChevRight = 'charm:chevron-right',
  ChevLeft = 'charm:chevron-left',
  ChevUp = 'charm:chevron-up',
  ChevDown = 'charm:chevron-down',
  Warning = 'clarity:warning-standard-solid',
  Document = 'carbon:document-blank',
  AddFilled = 'carbon:add-filled',
  Add = 'material-symbols:add',
  AddCircle = 'ic:outline-add-circle',
  Cross = 'radix-icons:cross-2',
  CancelRounded = 'material-symbols:cancel-rounded',
  RemoveCircle = 'ic:outline-remove-circle',
  Delete = 'fluent:delete-20-regular',
  Trash = 'akar-icons:trash-can',
  Trashv2 = 'octicon:trash-24',
  TrashFill = 'ph:trash-fill',
  InfoCircle = 'ant-design:info-circle-outlined',
  EllipsisVertical = 'clarity:ellipsis-vertical-line',
  Eye = 'akar-icons:eye',
  EyeOpen = 'akar-icons:eye-open',
  EyeClosed = 'akar-icons:eye-closed',
  EyeClosed16 = 'octicon:eye-closed-16',
  EyeSlash = 'akar-icons:eye-slashed',
  EyeSolid = 'ion:eye',
  Pencil = 'mdi:pencil-outline',
  SwapHorizontal = 'mdi:swap-horizontal',
  UserOutlined = 'ant-design:user-outlined',
  Person = 'akar-icons:person',
  Excel = 'vscode-icons:file-type-excel2',
  Pdf = 'vscode-icons:file-type-pdf2',
  Docx = 'vscode-icons:file-type-word',
  Caution = 'maki:caution',
  Play = 'ph:play',
  CirclePlusFill = 'akar-icons:circle-plus-fill',
  CircleDownFilled = 'fluent:arrow-circle-down-32-filled',
  TickCircleSolid = 'teenyicons:tick-circle-solid',
  MinusCircleFill = 'akar-icons:circle-minus-fill',
  Duplicate = 'humbleicons:duplicate',
  Refuse = 'flat-color-icons:cancel',
  InfoOutline = 'dashicons:info-outline',
  Building = 'ri:building-2-fill',
  Success = 'clarity:success-standard-solid',
  Upload = 'fluent:arrow-export-up-20-regular',
  Uploadv2 = 'mdi:arrow-expand-up',
  Uploadv3 = 'ant-design:upload-outline',
  Download = 'charm:download',
  Downloadv2 = 'mdi:arrow-collapse-down',
  ImportRight = 'fluent:arrow-import-20-regular',
  DragAndDrop = 'pixelarticons:drag-and-drop',
  Edit = 'bytesize:edit',
  VerticalDots = 'bi:three-dots-vertical',
  Check = 'bi:check-lg',
  CircleCheck = 'ci:circle-check',
  CheckFilled = 'ph:check-circle-fill',
  Triangle = 'mdi:triangle',
  Search = 'fe:search',
  SearchInput = 'search',
  CopyRight = 'emojione-monotone:copyright',
  Bell = 'ei:bell',
  SolidBell = 'fa-solid:bell',
  Filter = 'tdesign:filter',
  FilterFilled = 'ri:filter-fill',
  FilterRounded = 'material-symbols:filter-list-rounded',
  SplitUpLeft = 'fa6-solid:arrows-split-up-and-left',
  Drag = 'material-symbols:drag-indicator',
  ChevronRight = 'right chevron',
  ArrowThickRight = 'charm:arrow-right',
  ArrowThickRightUp = 'charm:arrow-up-right',
  ArrowThickRightDown = 'charm:arrow-down-right',
  EmailMessage = 'streamline:mail-send-email-message',
  AscSort = 'f7:sort-down',
  DescSort = 'f7:sort-up',
  AllSort = 'prime:sort-alt',
  Link = 'ci:link',
  Read = 'solar:check-read-linear',
  Unread = 'solar:unread-linear',
  ArchiveFilled = 'material-symbols:archive-sharp',
  UnarchiveFilled = 'material-symbols:unarchive-sharp',
  Comment = 'material-symbols:comment-outline',
  Reset = 'system-uicons:reset',
  Bot = 'lucide:bot',
  Send = 'bxs:send',
  MenuExpand = 'ri:expand-diagonal-2-line',
  MenuCollapse = 'ri:collapse-diagonal-2-line',
  MenuClose = 'mingcute:close-fill',
}
