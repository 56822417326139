import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { UsagePoint, evaluateLengthFromDates } from 'common/api/contracts2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const FitoutDepositAndFee = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const fitOut = activeContractEvent?.fitOut || null;

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: string) => {
    dispatch(updateActiveContractEventInput({ key: ['fitOut', key], value: value || null }));
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(updateActiveContractEventInput({ key: ['fitOut', key], value: value ? new Decimal(value) : null }));
  };

  const disabled = mode === MODE_OPTIONS.READ;

  useEffect(() => {
    if (fitOut?.startDate && fitOut?.endDate) {
      dispatch(evaluateLengthFromDates({ date1: fitOut?.startDate, date2: fitOut?.endDate, usage: UsagePoint.FitOut }));
    } else {
      onChange('duration', '');
    }
  }, [fitOut?.startDate, fitOut?.endDate]);

  return (
    <div className="grid">
      <div className="col-2">
        <InputField
          label="Fit-out deposit payable"
          dataTest=""
          type={InputFieldType.NUMBER}
          unit="(HK$)"
          fieldKey="fitOutDeposit"
          value={fitOut?.fitOutDeposit?.toString() || null}
          disabled={disabled}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>
      <div className="col-2">
        <InputField
          label="Fit-out fee"
          dataTest=""
          type={InputFieldType.NUMBER}
          unit="(HK$)"
          fieldKey="fitOutFee"
          value={fitOut?.fitOutFee?.toString() || null}
          disabled={disabled}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>

      <div className="col-2">
        <DateField
          label="Fit-out start date"
          fieldKey="startDate"
          dataTest=""
          value={fitOut?.startDate || null}
          disabled={disabled}
          onChange={onChange}
        />
      </div>

      <div className="col-2">
        <DateField
          label="Fit-out end date"
          fieldKey="endDate"
          dataTest=""
          value={fitOut?.endDate || null}
          disabled={disabled}
          onChange={onChange}
        />
      </div>

      <div className="col-2">
        <InputField
          label="Fit-out duration"
          type={InputFieldType.TEXT}
          fieldKey=""
          value={fitOut?.duration || null}
          disabled={true}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default FitoutDepositAndFee;
