import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface UploadTenantDocxMutationVariables {
  uploadFile: File;
}

export const UPLOAD_TENANT_DOCX = (uploadFile: File) => {
  const variables: UploadTenantDocxMutationVariables = {
    uploadFile,
  };

  return {
    mutation: gql`
      mutation ($uploadFile: Upload!) {
        uploadTenantDocx(uploadFile: $uploadFile) {
          comments {
            id
            author
            text
          }
          insertions {
            content
          }
          deletions {
            content
          }
        }
      }
    `,
    variables,
  };
};

export const uploadTenantDocx = createAsyncThunk('transactions/uploadTenantDocx', async ({ file }: { file: File }) => {
  const response = await client.mutate(UPLOAD_TENANT_DOCX(file));
  return response;
});
