import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { MenuItem, Tab, TabPane } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import ConfirmPopup from 'atoms/ConfirmPopup';
import ParameterAnswerAccordion, { GuidelineLocationTypes } from 'components/ParameterAnswerAccordion';
import { deleteIterationAnswers, updateIterationValue } from 'store/policies/policyDetailSlice';
import {
  deleteIterationAnswersTransaction,
  updateIterationValueTransaction,
} from 'store/transactions/transactionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { AnswerProps } from 'common/api/policies';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { CounterOptions, checkIfAnswerExists, getAnswers } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import './TableOfParametersQuestionsForm.scss';

const getCounter = (type: CounterOptions, parentId: string): HTMLElement | null => {
  const elem: HTMLElement | null = document.getElementById(`iteration-input-${type}-${parentId}`);
  return elem;
};

const clickCounter = (type: CounterOptions, parentId: string): void => {
  const elem: HTMLElement | null = getCounter(type, parentId);
  if (elem !== null) {
    elem.click();
  }
};

const incrementIterationValue = (
  parentId: string,
  answerType: string,
  dispatch: Dispatch<AnyAction>,
  debounceOnChange: () => void,
): void => {
  const checkCounter = getCounter(CounterOptions.Increment, parentId);
  if (checkCounter !== null) {
    clickCounter(CounterOptions.Increment, parentId);
  } else {
    const updateIteration = checkIfPoliciesPage() ? updateIterationValue : updateIterationValueTransaction;
    dispatch(updateIteration({ parentId, answerType }));
  }

  debounceOnChange();
};

const decrementIterationValue = (
  tabIndex: number | undefined,
  tableData: ParameterTable,
  dispatch: Dispatch<AnyAction>,
  currentIndex: number,
  setCurrentIndex: (value: number) => void,
  parentId: string,
  answerType: string,
  debounceOnChange: () => void,
): void => {
  if (currentIndex !== 0) {
    setCurrentIndex(currentIndex - 1);
  }
  const checkCounter = getCounter(CounterOptions.Decrement, parentId);
  const methodDecrement = checkIfPoliciesPage() ? deleteIterationAnswers : deleteIterationAnswersTransaction;
  dispatch(
    methodDecrement({
      tabIndex,
      tableData,
      parentId,
      answerType,
      checkCounter,
    }),
  );

  if (checkCounter !== null) {
    if (tabIndex === 0) {
      setTimeout(function () {
        clickCounter(CounterOptions.Decrement, parentId);
      }, 100);
    } else {
      clickCounter(CounterOptions.Decrement, parentId);
    }
  }

  debounceOnChange();
};

const QuestionContent = ({ columns, id: tableId }: any, answers: AnswerProps[], tabIndex: number): JSX.Element => {
  columns = orderBy(columns, obj => Number(obj.parameter.index), 'asc').filter(
    column =>
      !Array.isArray(column.applicableRowIndexes) ||
      column.applicableRowIndexes.length === 0 ||
      column.applicableRowIndexes.includes(tabIndex),
  );

  return (
    <div>
      {columns.map(({ parameter }: { parameter: Parameter }, index: number) => {
        const checkIfAnswer = checkIfAnswerExists({
          answers,
          answerType: parameter.answerType,
          parameterId: parameter.id,
          tableId,
          tabIndex,
        });

        return (
          <ParameterAnswerAccordion
            parameter={parameter}
            index={index}
            key={index}
            checkIfAnswer={checkIfAnswer}
            tableId={tableId}
            tabIndex={tabIndex}
            guidelineLocation={GuidelineLocationTypes.RIGHT}
          />
        );
      })}
    </div>
  );
};

const Questions = (
  iterationCount: number,
  tableData: ParameterTable,
  answers: AnswerProps[],
  onConfirm: (index: number) => void,
  parentId: string,
  answerType: AnswerTypes,
  debounceOnChange: () => void,
) => {
  const dispatch = useAppDispatch();
  let panes = [];

  if (iterationCount > 5) {
    iterationCount = 5;
  }

  for (let i = 0; i < iterationCount; i++) {
    panes.push({
      menuItem: (
        <MenuItem key={`Iteration ${i + 1}`}>
          Iteration {i + 1}
          <ConfirmPopup
            trigger={
              <div className="iteration-btn-delete color-red-soft">
                <Icon icon={Icons.Trash} />
              </div>
            }
            content="Do you really want to delete this iteration?"
            confirmButtonText="DELETE"
            onConfirm={() => onConfirm(i)}
            cancelButtonText="CANCEL"
          />
        </MenuItem>
      ),

      render: () => <TabPane attached={false}>{QuestionContent(tableData, answers, i)}</TabPane>,
    });
  }

  if (iterationCount !== 5) {
    panes.push({
      menuItem: (
        <MenuItem
          key="add-iteration"
          onClick={() => incrementIterationValue(parentId, answerType, dispatch, debounceOnChange)}
        >
          <span className="add-iteration-btn color-blue-very-dark-grayish">
            <Icon
              className="color-orange"
              icon={Icons.AddFilled}
            />
          </span>
        </MenuItem>
      ),
      render: () => <TabPane attached={false}></TabPane>,
    });
  }

  return panes;
};

interface TableOfParametersQuestionsFormProps {
  tableData: ParameterTable;
  answerValue: any;
  parameter: any;
  debounceOnChange: () => void;
}

const TableOfParametersQuestionsForm = ({
  tableData,
  answerValue,
  parameter: { id: parentId, answerType },
  debounceOnChange,
}: TableOfParametersQuestionsFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);
  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);

  if (tableData.columns?.length === 0) {
    return (
      <div className="table-of-parameters-answer-tab-box no-columns border-xl-gray-very-light bg-gray-very-light-v4">
        <h2 className="table-name">
          {tableData.name}:<span className="table-info">No table columns available for this table</span>
        </h2>
      </div>
    );
  } else {
    const onConfirm = (i: number): void =>
      decrementIterationValue(
        i,
        tableData,
        dispatch,
        currentIndex,
        setCurrentIndex,
        parentId,
        answerType,
        debounceOnChange,
      );

    return (
      <>
        <h2 className="table-name">{tableData.name}</h2>

        <Tab
          className="table-of-parameters-answer-tab-box border-xl-gray-very-light bg-gray-very-light-v4"
          activeIndex={currentIndex}
          onTabChange={(e, { activeIndex }) => {
            setCurrentIndex(activeIndex as number);
          }}
          menu={{ secondary: true, pointing: true }}
          panes={Questions(answerValue, tableData, answers, onConfirm, parentId, answerType, debounceOnChange)}
        />
      </>
    );
  }
};

export default TableOfParametersQuestionsForm;
