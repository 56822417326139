import { Dispatch as DispatchRedux } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { TransactionChannelClone } from 'common/_classes';
import { Dispatch, useEffect, useState } from 'react';
import { Button, Input, Popup, SearchProps, TextArea } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sortBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { getClauseNumber } from 'components/PreviewTab/Discussion';
import SearchBar from 'components/SearchBar';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import {
  DiscussionMode,
  resetMessages,
  setDiscussionMode,
  toggleDiscussionStatus,
  updateChannel,
  updateDiscussionModalStatus,
} from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import ThreadType from 'common/model/ThreadType';
import TransactionChannel from 'common/model/TransactionChannel';
import TransactionChannelStatus from 'common/model/TransactionChannelStatus';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { listContextParameters } from 'common/api/parameters';
import { AnswerProps } from 'common/api/policies';
import {
  ActiveTransactionChannel,
  createTransactionMessage,
  getTransactionChannel,
  listTransactionChannels,
  listTransactionMessages,
  updateTransactionChannel,
} from 'common/api/transactions';
import { conditionalClassName, getActiveDocument } from 'utils/tsHelper';
import { ParameterCount, getParameterCount } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import DiscussionTabs from './DiscussionTabs';
import './Discussion.scss';

export interface TransactionChannelWithClause extends TransactionChannel {
  clauseName: string | null | undefined;
  position: number;
  isGeneralDiscussion: boolean;
}

export enum TabType {
  Approval = 'APPROVAL',
  Preview = 'PREVIEW',
}

export const scrollDownChat = ({ tabType, approvalId }: { tabType: TabType; approvalId?: string }) => {
  const query = tabType === TabType.Approval ? `#list-of-messages-${approvalId}` : '.discussion-tab > .tab';
  let elem = document.querySelector(query);
  if (elem !== null) {
    elem.scrollTop = elem.scrollHeight;
  }
};

export const onCreateMessage = ({
  message,
  setMessage,
  setMessagesList,
  channelId,
  approvalId,
  amendmentFlag,
  thread,
  dispatch,
}: {
  message?: string;
  setMessage: Dispatch<any>;
  setMessagesList?: Dispatch<any>;
  approvalId?: string;
  channelId?: string;
  amendmentFlag: boolean;
  thread: ThreadType;
  dispatch: DispatchRedux;
}): void => {
  if (!message || !channelId) return;

  dispatch(createTransactionMessage({ message, channelId, amendmentFlag, thread })).then((response: any) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(listTransactionMessages({ channelId, thread })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (setMessagesList) {
            const messages = response.payload.data.listTransactionMessages;
            setMessagesList(messages);
            setTimeout(() => {
              scrollDownChat({ tabType: TabType.Approval, approvalId });
            }, 300);
          } else {
            scrollDownChat({ tabType: TabType.Preview });
          }
        }
      });
    }
  });
  setMessage('');
};

export const MessageInputBox = ({
  message,
  setMessage,
  amendmentFlag,
  thread,
  channelId,
  setMessagesList,
  approvalId,
}: {
  message: string;
  setMessage: Dispatch<any>;
  amendmentFlag: boolean;
  thread: ThreadType;
  channelId?: string;
  setMessagesList?: Dispatch<any>;
  approvalId?: string;
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <TextArea
        value={message}
        className="text-box p-xs"
        data-test="message-editor-box"
        onChange={e => setMessage(e.target.value)}
        placeholder="Type message..."
      />
      <Icon
        className={`message-icon ${conditionalClassName(
          amendmentFlag,
          'amendment',
        )} p-xxs m-r-sm m-t-xs ${conditionalClassName(message, 'cursor')}`}
        data-test="send-message-icon"
        icon={Icons.EmailMessage}
        onClick={() =>
          onCreateMessage({
            message,
            setMessage,
            setMessagesList,
            approvalId,
            channelId,
            amendmentFlag,
            thread,
            dispatch,
          })
        }
      />
    </>
  );
};

const Discussion = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [amendmentFlag, setAmendmentFlag] = useState<boolean>(false);
  const [thread, setThread] = useState<ThreadType>(ThreadType.Common);

  const {
    activeTransactionChannel,
    transactionChannels,
    discussionMode,
    isSearchingTransactionChannels,
    activeTransactionAnswers,
    activeTransaction,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { parametersCollection, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);

  const parametersAndDefinedAnswers: ParameterCount = getParameterCount(
    [...parametersCollection, ...parameterTablesCollection],
    activeTransactionAnswers,
  );

  const checkIfAllParametersDefined: boolean =
    parametersAndDefinedAnswers.definedAnswers === parametersAndDefinedAnswers.totalQuestions;

  const activeChannel: ActiveTransactionChannel = activeTransactionChannel;
  const trimTitle = () => {
    return activeChannel.title.length < 35 ? activeChannel.title : `${activeChannel.title.substring(0, 32)}...`;
  };

  useEffect(() => {
    if (activeChannel.id) {
      dispatch(listTransactionMessages({ thread })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setTimeout(() => {
            scrollDownChat({ tabType: TabType.Preview });
          }, 100);
        }
      });
    }
  }, [activeChannel.id, thread]);

  useEffect(() => {
    dispatch(listTransactionChannels({}));
  }, [DiscussionMode.List]);

  useEffect(() => {
    scrollDownChat({ tabType: TabType.Preview });
  }, [message]);

  useEffect(() => {
    dispatch(
      listContextParameters({
        context: ExecuteContext.Transaction,
        contextId: activeTransaction.id,
        provisionId: null,
        conditional: true,
        mode: ParameterModes.Detailed,
      }),
    );
  }, [dispatch]);

  const save = (): void => {
    dispatch(updateTransactionChannel());
    setIsOpen(false);
  };

  const onViewChannel = (id: string | undefined, nodeId: string | undefined): void => {
    scrollToClause(nodeId);
    dispatch(resetMessages());
    dispatch(getTransactionChannel({ id })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setDiscussionMode(DiscussionMode.View));
        setTimeout(() => {
          scrollDownChat({ tabType: TabType.Preview });
        }, 100);
      }
    });
  };

  const onSwitchStatus = (): void => {
    dispatch(toggleDiscussionStatus());
    dispatch(updateTransactionChannel());
  };

  const open: boolean = activeTransactionChannel.status === TransactionChannelStatus.Open;

  const goBackToList = (): void => {
    dispatch(listTransactionChannels({ search }));
    dispatch(setDiscussionMode(DiscussionMode.List));
  };

  const discussionsSize: number = transactionChannels.length;

  const activeDocument: HTMLElement | undefined = getActiveDocument()?.documentElement;

  const getClause = (clauseId: string | undefined) => {
    const scrollItem = activeDocument?.querySelector(`[data-node-id="${clauseId}"]`);
    return scrollItem;
  };

  const getClauses = () => {
    if (activeDocument !== null) {
      const scrollElements: NodeListOf<Element> | undefined =
        activeDocument?.querySelectorAll(`[data-node-type="clause"]`);
      return scrollElements;
    } else {
      return [];
    }
  };

  const clauses = getClauses();

  const getClausePosition = (clauseId: string | undefined) => {
    if (!clauses) return 0;
    for (let i = 0; i < clauses.length; i++) {
      const id = clauses[i].getAttribute('data-node-id');
      if (id === clauseId) {
        return i;
      }
    }
  };

  const getClauseDetails = (clauseId: string | undefined) => {
    const clause = getClause(clauseId);
    return {
      name: clause && getClauseNumber(clause, false),
      position: getClausePosition(clauseId) as number,
    };
  };

  const scrollToClause = (clauseId: string | undefined) => {
    const clause = getClause(clauseId);
    if (clause && clause !== null) {
      clause.scrollIntoView();
      window.scroll(0, 0);
    }
  };

  /*
   * Sorting of the discussion channels should be done by:
   *  - clause number if channel is associated with a clause,
   *  - the general discussions appear first, on top of the list,
   *    ordered by name.
   */
  const sortedTransactionChannels = (): TransactionChannelWithClause[] => {
    const channels: TransactionChannelWithClause[] = transactionChannels.map((channel: TransactionChannelClone) => {
      const channelCopy: TransactionChannelWithClause = { ...channel } as TransactionChannelWithClause;

      if (channel.node) {
        const clause = getClauseDetails(channel.node.id);
        channelCopy.clauseName = clause.name;
        channelCopy.position = clause.position;
        channelCopy.isGeneralDiscussion = false;
      } else {
        channelCopy.clauseName = '';
        channelCopy.position = -1;
        channelCopy.isGeneralDiscussion = true;
      }
      return channelCopy;
    });
    return sortBy(channels, ['position', 'title']);
  };

  const openCreateDiscussionModal = () => {
    dispatch(updateDiscussionModalStatus(true));
  };

  const sortedChannels = sortedTransactionChannels();

  const onSearch = (e: React.MouseEvent<HTMLElement>, { value }: SearchProps) => {
    setSearch(value!);
    dispatch(
      listTransactionChannels({
        search: value,
        isSearching: true,
      }),
    );
  };

  const checkIfParametersLinked = (channelId: string) => {
    return (
      activeTransactionAnswers.filter(
        (answer: AnswerProps) => answer.transactionMessage?.transactionChannel.id === channelId,
      ).length !== 0
    );
  };

  const checkIfParametersPending = (channelId: string) => {
    return (
      activeTransactionAnswers.filter(
        (answer: AnswerProps) =>
          answer?.transactionMessage?.transactionChannel.id === channelId &&
          [ValidApprovalStates.Pending, ValidApprovalStates.Approved, ValidApprovalStates.Rejected].includes(
            answer?.approvalState as ValidApprovalStates,
          ),
      ).length !== 0
    );
  };

  const DiscussionsList = () => {
    if (discussionsSize === 0 && !search && !isSearchingTransactionChannels) {
      return (
        <p>
          No discussions created yet, please click the “ADD GENERAL DISCUSSION” button above to create a general
          discussion or click on a clause number in a document to open a clause related discussion.
        </p>
      );
    } else {
      return (
        <>
          <SearchBar
            value={search}
            searching={isSearchingTransactionChannels}
            search={onSearch}
            className="m-b-s"
          />

          {discussionsSize === 0 ? (
            <p>No discussion matches search query</p>
          ) : (
            <div
              className="list-of-channels"
              data-test="list-of-channels"
            >
              {sortedChannels.map((channel: TransactionChannelWithClause) => (
                <div
                  className={`m-b-xxs p-xxs discussion-list-row ${
                    channel.status === TransactionChannelStatus.Resolved ? 'resolve-channel' : ''
                  }`}
                  key={`channel-${channel.id}`}
                  onClick={() => onViewChannel(channel.id, channel.node?.id)}
                >
                  {channel.isGeneralDiscussion ? (
                    <div className="m-r-xs general-discussion-indicator">G</div>
                  ) : channel.clauseName ? (
                    `${channel.clauseName} - `
                  ) : (
                    'Un - '
                  )}
                  {channel.title}
                  {checkIfParametersLinked(channel.id) ? (
                    <Badge
                      className="m-l-xs"
                      badgeColor={checkIfAllParametersDefined ? BadgeColor.GREEN : BadgeColor.PURPLE}
                    >
                      Parameters
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {checkIfParametersPending(channel.id) ? (
                    <Badge
                      className="m-l-xs"
                      badgeColor={BadgeColor.ORANGE}
                    >
                      Pending
                    </Badge>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      );
    }
  };

  const updateAmendment = (status: boolean) => {
    setMessage('');
    setAmendmentFlag(status);
  };

  let views: JSX.Element = <></>;

  /* There are 2 different views:
   *  - DiscussionMode.List : list all the discussion related to that transaction
   *  - DiscussionMode.View : to view the discussion thread of a specific discussion
   **/
  if (discussionMode === DiscussionMode.View) {
    views = (
      <>
        {/* Discussion tab Header part (channel thread mode) with
             - 'breadcrumb' to go back to discussion list
             - title
             - button with popup to modify title
             - status/status switch button
         */}
        <div className="discussion-header p-b-xs d-flex justify-space-between align-center">
          <div className="d-flex align-center">
            <span
              className="edit-btn p-xxs m-r-s"
              onClick={goBackToList}
            >
              <Icon
                icon={Icons.ArrowBackIOS}
                className="m-l-xs"
              />
            </span>
            <h2
              className="title m-none"
              data-test="channel-title"
            >
              {trimTitle()}
            </h2>
          </div>

          <div className="d-flex align-center">
            <Popup
              trigger={
                <span
                  className="edit-btn p-xxs m-r-s"
                  data-test="edit-button"
                >
                  <Icon icon={Icons.Pencil} />
                </span>
              }
              className="popup-title"
              open={isOpen}
              onOpen={() => setIsOpen(!isOpen)}
              content={
                <>
                  <Input
                    className="title-input"
                    data-test="edit-channel-title"
                    value={activeChannel.title}
                    onChange={e => dispatch(updateChannel({ key: 'title', value: e.target.value }))}
                  />
                  <div className="flex">
                    <Button
                      className="cancel-btn m-t-xs p-t-xs"
                      onClick={() => setIsOpen(false)}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className="save-btn m-t-xs p-t-xs"
                      onClick={save}
                    >
                      SAVE
                    </Button>
                  </div>
                </>
              }
              on="click"
            />
            <Button
              size="mini"
              className={`status ${!open ? 'status-resolved' : ''}`}
              data-test="update-status"
              onClick={onSwitchStatus}
            >
              {activeTransactionChannel.status}
            </Button>
          </div>
        </div>

        {/* Discussion threads, inluding the thread switch */}
        <DiscussionTabs
          setThread={setThread}
          thread={thread}
          checkIfAllParametersDefined={checkIfAllParametersDefined}
        />

        {/* Toggle message type between {message, Amendment} */}
        <div className="m-t-xxs m-b-xxs message-type-selector">
          <span>
            <Button
              className={`type-selector-btn ${conditionalClassName(!amendmentFlag, 'active')}`}
              data-test="message-type"
              onClick={() => updateAmendment(false)}
            >
              Message
            </Button>
            <Button
              className={`type-selector-btn ${conditionalClassName(amendmentFlag, 'active')}`}
              data-test="amendment-type"
              onClick={() => updateAmendment(true)}
            >
              Amendment
            </Button>
          </span>
        </div>

        {/* Text Area to type message or Amendment  */}
        <div className={`${!amendmentFlag ? 'message-box' : 'message-box-editor'} m-b-sm`}>
          {amendmentFlag ? (
            <div
              className="editor-box"
              data-test="amendment-editor"
            >
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                value={message}
                data-test="mimi"
                init={{
                  paste_as_text: true,
                  menubar: false,
                  statusbar: false,
                  plugins: 'lists codesample code',
                  placeholder: 'Type proposed amendment...',
                  height: '14.7857rem',
                  toolbar:
                    'formatselect bold underline italic forecolor backcolor bullist numlist alignleft aligncenter alignright alignjustify codesample code removeformat',
                  content_style:
                    'body { font-family:Urbanist; font-size:14px; color: #041630; font-weight: 400; caret-color: #E69701}',
                }}
                onEditorChange={(content: string) => setMessage(content)}
              />
            </div>
          ) : (
            <MessageInputBox
              message={message}
              setMessage={setMessage}
              amendmentFlag={amendmentFlag}
              thread={thread}
              channelId={activeChannel.id}
            />
          )}
        </div>
      </>
    );
  } else {
    views = (
      <>
        {/* Discussion tab Header part (list channel mode) with
             - title
             - button to add new channel discussion
          */}
        <div className="discussion-header p-b-xs m-b-sm d-flex justify-space-between align-center">
          <h2
            className="title m-none"
            onClick={() => dispatch(listTransactionChannels({}))}
          >
            List of discussions
          </h2>

          {/* NOTE 
            The CreateDiscussionModal the button below activates is already on this page.
            It has already been imported in "src\components\PreviewTab\index.tsx" file, so 
            no need to re-import it here */}
          <Button
            className="btn grey-bg"
            onClick={openCreateDiscussionModal}
          >
            ADD GENERAL DISCUSSION
          </Button>
        </div>

        <DiscussionsList />
      </>
    );
  }

  return <div className="discussion-wrapper p-t-xs">{views}</div>;
};

export default Discussion;
