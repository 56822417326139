import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { clearActiveParameters, setActiveParameters } from 'store/miscellaneous/miscellaneousSlice';
import { resetActiveNode } from 'store/nodes/nodesSlice';
import ConditionType from 'common/model/ConditionType';
import ExecuteContext from 'common/model/ExecuteContext';
import NodeType from 'common/model/NodeType';
import { getParameter, getParameterTable } from 'common/api/parameters';
import { ContentClone } from 'common/api/provisions';
import { ParamRefInput } from 'utils/types/nodes';
import { parseContent } from 'utils/utils-string';
import Discussion from './Discussion';
import TableOfContent from './Tabs/TableOfContent';
import './TenantPreviewSideMenu.scss';

export enum PREVIEW_INFORMATION_TABS_OFFSET {
  TABLE_OF_CONTENTS = 0,
  CONTROL = 1,
  DISCUSSION = 2,
}

export const parseSelectedContent = (contents: ContentClone[], activeDocTypeId: string | null) => {
  let content: ContentClone[] | string | undefined = contents?.filter(
    ({ documentType }) => activeDocTypeId === documentType?.id,
  );

  if (content && content?.length !== 0) {
    content = content[0].content;
  } else {
    content = '';
  }

  return parseContent(content);
};

const TenantPreviewSideMenu = ({ context, isTenant = false }: { context: ExecuteContext; isTenant?: boolean }) => {
  const dispatch = useAppDispatch();

  const { activeTab } = useAppSelector((state: RootState) => state.hiddenMenu);
  const { activePolicyContents } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransactionContents } = useAppSelector((state: RootState) => state.transactionDetail);
  const { activeNode } = useAppSelector((state: RootState) => state.nodes);

  const contents: ContentClone[] = context === ExecuteContext.Policy ? activePolicyContents : activeTransactionContents;

  const nodeType = activeNode?.type;

  const getParameterDetails = (paramRef: ParamRefInput) => {
    const { parameterId, tableId } = paramRef;

    if (!parameterId) return;

    const promises = [
      // @ts-ignore
      dispatch(getParameter({ id: parameterId, loading: false })),
    ];

    if (tableId) {
      // @ts-ignore
      promises.push(
        // @ts-ignore
        dispatch(getParameterTable({ id: tableId, loading: false })),
      );
    }

    Promise.all(promises).then((responses: any) => {
      const [parameterResponse, tableResponse] = responses;

      if (parameterResponse.meta.requestStatus === 'fulfilled') {
        let tableName: null | string = null;

        if (tableId) {
          if (tableResponse.meta.requestStatus === 'fulfilled') {
            tableName = tableResponse.payload.data.getParameterTable.name;
          }
        }

        const data = {
          ...parameterResponse.payload.data.getParameter,
          tableName,
          paramRef,
        };
        dispatch(setActiveParameters({ data }));
      }
    });
  };

  useEffect(() => {
    dispatch(clearActiveParameters());
    if (nodeType !== NodeType.Provision) {
      const getKey = ({ parameterId, tableId, index, offset, position }: ParamRefInput) =>
        `${parameterId}-${tableId}-${index}-${offset}-${position}`;

      const listOfParameters = activeNode?.conditions;

      const uniqueParameters: { [key: string]: boolean } = {};

      if ([NodeType.Clause, NodeType.Text].includes(nodeType as NodeType)) {
        listOfParameters?.forEach(({ list }) => {
          list.forEach(({ paramRef, paramRef2, type }) => {
            if (paramRef) {
              const key = getKey(paramRef);

              if (!uniqueParameters[key]) {
                uniqueParameters[key] = true;
                getParameterDetails(paramRef);
              }
            }

            if (type === ConditionType.ParamParam && paramRef2) {
              const key = getKey(paramRef2);

              if (!uniqueParameters[key]) {
                uniqueParameters[key] = true;
                getParameterDetails(paramRef2);
              }
            }
          });
        });
      } else {
        activeNode?.paramRefs?.forEach(paramRef => {
          if (paramRef) {
            const key = getKey(paramRef as ParamRefInput);

            if (!uniqueParameters[key]) {
              uniqueParameters[key] = true;
              getParameterDetails(paramRef as ParamRefInput);
            }
          }
        });
      }
    }
  }, [activeNode]);

  useEffect(() => {
    dispatch(resetActiveNode());
    dispatch(clearActiveParameters());
  }, []);

  const menuOptions: TabMenuProps[] = [
    {
      key: 'sidebar-tab-table',
      label: 'Table of Contents',
      dataTest: 'table-of-contents',
      customTab: (
        <TableOfContent
          contents={contents}
          nodeType={activeNode?.type}
        />
      ),
    },
    {
      key: 'sidebar-tab-discussion',
      label: 'Negotiation',
      dataTest: 'negotiation-tab',
      customTab: <Discussion />,
      class: context === ExecuteContext.Policy ? 'hide-discussion' : '',
    },
  ];

  return (
    <TabReact
      className="preview-sidebar-menu-tab"
      dataTest="preview-sidebar-menu-tab"
      activeTabIndex={activeTab}
      onTabChange={(activeIndex: number) => dispatch(updateSidebarTab(activeIndex))}
      tabMenu={menuOptions}
      nested={true}
    />
  );
};

export default TenantPreviewSideMenu;
