enum ConditionComparator {
  All = 'ALL',
  Any = 'ANY',
  Equal = 'EQUAL',
  NotAll = 'NOT_ALL',
  NotEqual = 'NOT_EQUAL',
  None = 'NONE',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  IsFirst = 'IS_FIRST',
  IsNotFirst = 'IS_NOT_FIRST',
  IsLast = 'IS_LAST',
  IsNotLast = 'IS_NOT_LAST',
  Zero = 'ZERO',
  One = 'ONE',
  GreaterThanOrEqualTwo = 'GREATER_THAN_OR_EQUAL_TWO'
}
export default ConditionComparator;
