import { ParameterTableColumnForm } from 'common/_classes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import SectionComponent from 'layouts/SectionComponent';
import TabContainer from 'components/TabContainer';
import { addColumn, deleteColumn, updateColumnPosition } from 'store/parameters/parameterDetailSlice';
import SortOrder from 'common/model/SortOrder';
import { listFormatters } from 'common/api/formatters';
import { MODE_OPTIONS, PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import {
  MultiLevelParamPaginationProps,
  listParameterGroups,
  listParameterTablesPage,
  listParameters,
} from 'common/api/multiLevelMenu';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import ParameterInformationTabToolbar from '../ParameterInformationTab/ParameterInformationTabToolbar';
import ColumnConditions from './ColumnConditions';
import ColumnForm from './ColumnForm';
import './ColumnDefinitionTab.scss';

interface CustomPaginationParameters extends Omit<MultiLevelParamPaginationProps, 'sortOrder'> {
  totalPages: number;
}

export interface ColumnDefinitionProps {
  mode: MODE_OPTIONS;
  columns: Array<ParameterTableColumnForm>;
  checkCreate: boolean;
}

enum PositionChange {
  Forward = 'forward',
  Backward = 'back',
}

const Content = ({ mode, columns, checkCreate }: ColumnDefinitionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  //const { parameterGroups, parameterTables, parameters } = useAppSelector((state: RootState) => state.multiLevelMenu);
  // const { showCondition } = useAppSelector((state: RootState) => state.parameterDetail);

  useEffect(() => {
    const FIRST_PAGINATION: number = 1000,
      params: MultiLevelParamPaginationProps = {
        first: FIRST_PAGINATION,
        sortOrder: SortOrder.Asc,
      };
    const paramsWithTotalPages: CustomPaginationParameters = {
      ...params,
      totalPages: FIRST_PAGINATION,
    };

    dispatch(listFormatters(paramsWithTotalPages));
    dispatch(listParameters({ ...params, choices: true }));
    dispatch(listParameterGroups());
    dispatch(listParameterTablesPage(params));
  }, [dispatch]);

  const updateIndex = (index: number, type: PositionChange): void => {
    if (type === PositionChange.Backward) {
      if (index !== 0) {
        dispatch(updateColumnPosition({ currentIndex: index - 1, nextIndex: index }));
      }
    } else {
      if (index !== columns.length - 1) {
        dispatch(updateColumnPosition({ currentIndex: index + 1, nextIndex: index }));
      }
    }
  };

  const ComponentHeader = ({ index, column }: { index: number; column: ParameterTableColumnForm }) => {
    return (
      <>
        <span className="column-title m-r-m">{column.title}</span>
        {!disabled && (
          <span className="m-l-auto d-flex align-center">
            <Icon
              icon={Icons.ArrowLeft}
              className="title-icons color-blue-very-dark-grayish cursor-pointer m-r-xs"
              data-test="left-arrow-key"
              onClick={!disabled && (() => updateIndex(index, PositionChange.Backward))}
            />
            <Icon
              icon={Icons.ArrowRight}
              className="title-icons color-blue-very-dark-grayish cursor-pointer m-r-sm"
              data-test="right-arrow-key"
              onClick={!disabled && (() => updateIndex(index, PositionChange.Forward))}
            />
            <Icon
              icon={Icons.Delete}
              className="title-icons color-red-soft cursor-pointer"
              data-test="delete-button"
              onClick={() => dispatch(deleteColumn({ index }))}
            />
          </span>
        )}
      </>
    );
  };

  return (
    <Form className="parameters-column-definition-content m-b-l">
      <Grid className="pm-none">
        {columns.length === 0 && !checkCreate && <h2 className="no-column">No column exists</h2>}
        <Grid.Row columns={3}>
          {columns.map((column: ParameterTableColumnForm, index: number) => (
            <Grid.Column
              className="parameters-column-definition-content-container m-b-l"
              data-test="parameters-columns"
              key={index}
            >
              <SectionComponent
                mode-read={`${disabled}`}
                data-input={`${checkNotEmpty(column.title)}`}
                className="inner-content"
                title={
                  <ComponentHeader
                    index={index}
                    column={column}
                  />
                }
              >
                <ColumnForm
                  mode={mode}
                  column={column}
                  index={index}
                />
                <ColumnConditions
                  column={column}
                  disabled={disabled}
                />
              </SectionComponent>
            </Grid.Column>
          ))}

          {!disabled && (
            <Grid.Column
              className="parameters-column-definition-content-container add-column-box"
              onClick={() => dispatch(addColumn())}
            >
              <SectionComponent className="inner-content cursor-pointer">
                <div
                  className="d-flex align-center justify-center column-form-container"
                  data-test="add-column-button"
                >
                  <span className="circle-m plus-icon">
                    <Icon icon={Icons.Add} />
                  </span>
                  <span className="m-l-xs m-t-xxs color-blue-very-dark-grayish">Add new column to the table</span>
                </div>
              </SectionComponent>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const ColumnDefinitionTab = (): JSX.Element => {
  const location = useLocation();

  const checkCreate: boolean = location.pathname.includes('create');
  const { activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const { columns } = activeTableParameter;

  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);

  return (
    <TabContainer
      tabTitle="Column Definitions"
      tabToolbar={
        (checkCreate && columns.length !== 0) ||
        (!checkCreate && (
          <ParameterInformationTabToolbar
            mode={mode}
            setMode={setMode}
            isCreatePage={checkCreate}
            type={PARAMETER_TABLE_IMPLEMENTATION.TABLE}
          />
        ))
      }
      mode={mode}
    >
      <Content
        // @ts-ignore
        columns={columns}
        checkCreate={checkCreate}
      />
    </TabContainer>
  );
};

export default ColumnDefinitionTab;
