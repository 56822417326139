import { createSlice, current } from '@reduxjs/toolkit';
import {
  CContract2,
  CContractEvent,
  Contract2EventsInputClone,
  Contract2InputClone,
} from 'common/_classes/contractsV2';
import { CValuationReport } from 'common/_classes/valuationReport2';
import Decimal from 'decimal.js';
import { toast } from 'react-toastify';
import Contract2Events from 'common/model/Contract2Events';
import ContractValuation2 from 'common/model/ContractValuation2';
import RateType from 'common/model/RateType';
import RentReview from 'common/model/RentReview';
import {
  UsagePoint,
  calculateAndUpdateRentReviewCalculatedFields,
  createContract2,
  createContractAndEvent,
  createContractEvent,
  evaluateLengthFromDates,
  evaluateManagementChargeOrAcFeeFinalCharge,
  evaluateRenewalRightsDates,
  getContract2,
  getContractValuationReport,
  prepContractDocsForUpload,
} from 'common/api/contracts2';
import { listLandlordAndTenantEntities } from 'common/api/entities';
import { DROPDOWN_OPTION } from 'utils/UI';
import { updateNestedObject } from 'utils/utils-nested-object-update';

interface LANDLORD_AND_TENANTS_OPTIONS extends DROPDOWN_OPTION {
  nickname: string;
}

interface ContractDetailsStateV2 {
  activeContract: Contract2InputClone | null;
  activeContractEvent: Contract2EventsInputClone | null;
  contractEvents: Contract2Events[];
  isLoading: boolean;
  landlordEntitiesOptions: LANDLORD_AND_TENANTS_OPTIONS[];
  tenantEntitiesOptions: LANDLORD_AND_TENANTS_OPTIONS[];
  valuationReport: CValuationReport[];
  unsavedModalStatus: boolean;
  clickedURL: string;
  termination: any;
}

const initialState: ContractDetailsStateV2 = {
  activeContract: null,
  activeContractEvent: null,
  contractEvents: [],
  isLoading: false,
  landlordEntitiesOptions: [],
  tenantEntitiesOptions: [],
  valuationReport: [],
  unsavedModalStatus: false,
  clickedURL: '',
  termination: null,
};

const contractDetailSliceV2 = createSlice({
  name: 'contractDetailV2',
  initialState,
  reducers: {
    cancelContractEventEdit: state => {
      const { id } = state.activeContractEvent || {};

      const event = state.contractEvents.find(({ id: eventId }) => eventId === id);

      if (event) {
        state.activeContractEvent = new CContractEvent(current(event));
      }
    },
    addContractDocs: (state, action) => {
      // TODO by Adebayo
    },
    deleteContractDocs: (state, action) => {
      // TODO by Adebayo
    },
    setActiveContractEvent: (state, action) => {
      const event = state.contractEvents.find(({ id: eventId }) => eventId === action.payload);

      if (event) {
        state.activeContractEvent = new CContractEvent(current(event));
      }
    },

    initContractAndContractEventCreation: state => {
      state.activeContract = new CContract2(null);
      state.activeContractEvent = new CContractEvent(null);
      state.unsavedModalStatus = false;
      state.clickedURL = '';
    },
    clearContractCreation: state => {
      state.activeContract = null;
      state.activeContractEvent = null;
      state.contractEvents = [];
      state.unsavedModalStatus = false;
      state.clickedURL = '';
    },
    updateActiveContractInput: (state, action) => {
      if (state.activeContract) {
        const { key, value } = action.payload;
        state.activeContract = updateNestedObject(state.activeContract, key, value);
      }
    },
    updateActiveContractEventInput: (state, action) => {
      if (state.activeContractEvent) {
        const { key, value } = action.payload;
        state.activeContractEvent = updateNestedObject(state.activeContractEvent, key, value);
      }
    },
    setUnsavedModalStatus: (state, action) => {
      const { status, url } = action.payload;
      state.unsavedModalStatus = status;
      state.clickedURL = url ? url : '';
    },
    // TODO-CONTRACT-V2: these have been copied across from contracts 1, needs updating
    // Used in src/views/contracts-v2/ContractForm/Termination/index.tsx
    clearTermination: state => {
      console.log('clearTermination needs updating');
      // state.termination.terminationDate = null;
      // state.termination.terminationDescription = null;
      // state.termination.terminationReason = null;
    },
    updateContractTermination: (state, action) => {
      console.log('updateContractTermination needs updating');
      // const { key, value } = action.payload;
      // state.activeContract.termination[key] = value;
    },
  },
  extraReducers: builder => {
    // evaluateLengthFromDates
    builder.addCase(evaluateLengthFromDates.fulfilled, (state, action) => {
      if (state.activeContract) {
        const duration = action.payload.data.evaluateLengthFromDates;

        const { index, usage } = action.meta.arg;

        if (state.activeContractEvent) {
          if (usage === UsagePoint.RentFreePeriod && index !== undefined) {
            state.activeContractEvent.rentFreePeriods[index].duration = duration;
          } else if (usage === UsagePoint.FitOut) {
            state.activeContractEvent.fitOut.duration = duration;
          }
        }
      }
    });

    builder.addCase(evaluateLengthFromDates.rejected, (state, action) => {
      console.error(action.error);
      toast.error('evaluateLengthFromDates API request rejected');
    });

    // evaluateManagementChargeOrAcFeeFinalCharge
    builder.addCase(evaluateManagementChargeOrAcFeeFinalCharge.fulfilled, (state, action) => {
      if (state.activeContract) {
        const finalCharge = action.payload.data.evaluateManagementChargeOrAcFeeFinalCharge;

        const { index, usage } = action.meta.arg;

        if (usage === UsagePoint.ManagementCharge) {
          const { standardRate, standardRateDiscount, type } = state.activeContract.managementCharges[index];

          if (type === RateType.Discount && standardRate && standardRateDiscount) {
            state.activeContract.managementCharges[index].finalCharge = finalCharge;
          }
        } else if (usage === UsagePoint.ACFee) {
          const { standardRate, standardRateDiscount, type } = state.activeContract.acFees[index];

          if (type === RateType.Discount && standardRate && standardRateDiscount) {
            state.activeContract.acFees[index].finalCharge = finalCharge;
          }
        }
      }
    });

    builder.addCase(evaluateManagementChargeOrAcFeeFinalCharge.rejected, (state, action) => {
      console.error(action.error);
      toast.error('evaluateManagementChargeOrAcFeeFinalCharge API request rejected');
    });

    // evaluateRenewalRightsDates
    builder.addCase(evaluateRenewalRightsDates.fulfilled, (state, action) => {
      if (state.activeContractEvent) {
        const renewalRightsDates = action.payload.data.evaluateRenewalRightsDates;

        const renewalRights = state.activeContractEvent.renewalRights.map((right, index) => {
          const data = renewalRightsDates[index];
          return { ...right, startDate: data.startDate, endDate: data.endDate };
        });

        state.activeContractEvent.renewalRights = renewalRights;
      }
    });
    builder.addCase(evaluateRenewalRightsDates.rejected, (state, action) => {
      console.error(action.error);
      toast.error('evaluateRenewalRightsDates API request rejected');
    });

    // listLandlordAndTenantEntities
    builder.addCase(getContractValuationReport.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getContractValuationReport.fulfilled, (state, action) => {
      const reports = action.payload.data.valuationReport2;
      state.valuationReport = reports
        .filter((report: ContractValuation2) => report !== null)
        .map((report: ContractValuation2) => new CValuationReport(report));
      state.isLoading = false;
    });
    builder.addCase(getContractValuationReport.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getContractValuationReport API request rejected');
    });

    // listLandlordAndTenantEntities
    builder.addCase(listLandlordAndTenantEntities.fulfilled, (state, action) => {
      const lanlordsEdges = action.payload?.data?.landlord?.edges ?? [];
      const landlordsList = lanlordsEdges.map((element: any) => element.node);
      const tenantsEdges = action.payload?.data?.tenant?.edges ?? [];
      const tenantsList = tenantsEdges.map((element: any) => element.node);

      state.landlordEntitiesOptions = landlordsList.map((item: any, index: number) => ({
        key: index,
        text: item.name,
        value: item.id,
        nickname: item.nickname,
      }));

      state.tenantEntitiesOptions = tenantsList.map((item: any, index: number) => ({
        key: index,
        text: item.name,
        value: item.id,
        nickname: item.nickname,
      }));
    });
    builder.addCase(listLandlordAndTenantEntities.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An unexpected error occured Updating Landlord and Tenant Options');
    });

    // createContractAndEvent
    builder.addCase(createContractAndEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContractAndEvent.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract created successfully');
    });
    builder.addCase(createContractAndEvent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContractAndEvent API request rejected');
    });

    // createContract2
    builder.addCase(createContract2.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContract2.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract created successfully');
    });
    builder.addCase(createContract2.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContract2 API request rejected');
    });

    // createContractEvent
    builder.addCase(createContractEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContractEvent.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract updated successfully');
    });
    builder.addCase(createContractEvent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContractEvent API request rejected');
    });

    // prepContractDocsForUpload
    builder.addCase(prepContractDocsForUpload.fulfilled, (state, action) => {});

    builder.addCase(prepContractDocsForUpload.rejected, (state, action) => {
      toast.error(action.error.message);
    });

    // getContract2
    builder.addCase(getContract2.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getContract2.fulfilled, (state, action) => {
      console.log(action);
      const contract = action?.payload?.data?.getContract2;
      state.contractEvents = contract.events;
      state.activeContract = new CContract2(contract);
      state.activeContractEvent = new CContractEvent(contract.events[contract.events.length - 1]);
      state.isLoading = false;
    });
    builder.addCase(getContract2.rejected, (state, action) => {
      console.error(action.error);
      const errorMessage = action?.error?.message
        ? action.error.message
        : 'An error occured while trying to fetch the contract data';
      state.isLoading = false;
      toast.error(errorMessage);
    });

    // calculateAndUpdateRentReviewCalculatedFields
    builder.addCase(calculateAndUpdateRentReviewCalculatedFields.fulfilled, (state, action) => {
      if (state.activeContractEvent) {
        const values = action?.payload?.data?.calculateAndUpdateRentReviewCalculatedFields || [];

        values.forEach(({ faceRentPsfPcm, effectiveRentPcm, effectiveRentPsfPcm }: RentReview, index: number) => {
          state.activeContractEvent!.rentReview[index].faceRentPsfPcm = faceRentPsfPcm
            ? new Decimal(faceRentPsfPcm)
            : null;

          state.activeContractEvent!.rentReview[index].effectiveRentPcm = effectiveRentPcm
            ? new Decimal(effectiveRentPcm)
            : null;

          state.activeContractEvent!.rentReview[index].effectiveRentPsfPcm = effectiveRentPsfPcm
            ? new Decimal(effectiveRentPsfPcm)
            : null;
        });
      }
    });
    builder.addCase(calculateAndUpdateRentReviewCalculatedFields.rejected, (state, action) => {
      console.error(action.error);
      toast.error('calculateAndUpdateRentReviewCalculatedFields API request rejected');
    });
  },
});

export const {
  initContractAndContractEventCreation,
  updateActiveContractInput,
  updateActiveContractEventInput,
  deleteContractDocs,
  addContractDocs,
  clearContractCreation,
  setActiveContractEvent,
  cancelContractEventEdit,
  setUnsavedModalStatus,
  clearTermination,
  updateContractTermination,
} = contractDetailSliceV2.actions;

export default contractDetailSliceV2.reducer;
