import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { LIST_PROPERTIES_FOR_CONTRACT_CREATION } from 'common/api/properties';

export const listLandlordProperties = createAsyncThunk(
  'miscellaneous/listLandlordProperties',
  async (landlordId: string | null) => {
    console.log('landlordId', landlordId);
    const response = await client.query(LIST_PROPERTIES_FOR_CONTRACT_CREATION(landlordId));
    return response;
  },
);
